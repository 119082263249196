class HomePage {
    constructor() {
        this.init_toggle_package_listener();
    }

    init_toggle_package_listener() {
        document.querySelectorAll('.radio_price_button').forEach(v => {
            v.addEventListener('click', this.toggle_package_selection.bind(this));
        });
    }

    toggle_package_selection(e) {
        const radio = e.target;

        // Toggle the selected-ness
        document.querySelectorAll('.radio_price_button').forEach(v => v.classList.remove('selected'));
        radio.classList.add('selected');

        const project_type = radio.dataset.projectType;
        const btn = (project_type === 'custom' ? 'other_btn' : 'wedding_btn');
        document.getElementById(btn).click();
        document.querySelector('[name=specific_type]').value = project_type;

        const contact_us = document.getElementById('contact_us').offsetTop;
        window.scrollTo({
            top: contact_us - 70,
            behavior: "smooth"
        });
    }
}

let homepage;
document.addEventListener("DOMContentLoaded", () => {
    homepage = new HomePage();
});
